.dashboard {
  width: 100%;

  @at-root #{&}__chapters {
    .chapters {
      margin-bottom: 20px;

      .chapter {
        width: 180px;
        height: min-content;
        border-radius: 10px;
        margin-right: 30px;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        transition: box-shadow .3s;

        @at-root #{&}__image {
          height: 100px;
          background-color: rgba(0, 0, 0, 0.12);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        @at-root #{&}__content {
          padding: 12px;

          .title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 12px;
          }

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-bottom: 10px;
          }

          .read-more {
            color: #40a9ff;
            font-weight: bold;
            font-size: 12px;
          }
        }
      }

      .chapter:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); 
      }
    }
  }
}