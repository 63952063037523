.video-container {
  width: 100%;
  max-width: 477px;
  min-height: 221px;
  margin-bottom: 30px;
  
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: (363px / 640px) * 100%;
  }
  
  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
}