.projects {
  width: 100%;

  .projects__group-projects, .projects__individual-projects {
    .projects {
      margin: 20px 0;

      .project {
        width: 200px;
        height: min-content;
        border-radius: 8px;
        margin-right: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: box-shadow .3s;

        @at-root #{&}__content {
          padding: 12px;

          .title {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 12px;
          }

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-bottom: 20px;
          }

          .explore {
            color: #58CB58;
            font-weight: bold;
            font-size: 12px;
          }
        }
      }

      .project:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); 
      }
    }
  }
}

.view-project {
  width: 100%;

  .view-project__requirements {
    .requirements {
      margin: 20px 0;

      .requirement {
        width: 200px;
        height: min-content;
        border-radius: 10px;
        margin-right: 30px;
        margin-bottom: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: box-shadow .3s;
        cursor: pointer;

        @at-root #{&}__content {
          padding: 12px;

          .title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-bottom: 20px;
          }

          .points {
            color: #58CB58;
            font-weight: bold;
            font-size: 12px;
          }
        }
      }

      .requirement:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); 

        .requirement__content {
          .title {
            color: #1890FF !important;
          }
        }
      }
    }
  }
}