.downloads {
  width: 100%;

  .downloads__quick-access, .downloads__all-downloads {
    .downloads {
      margin: 10px 0;

      .download {
        height: min-content;
        border-radius: 8px;
        margin-right: 30px;
        margin-bottom: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: box-shadow .3s;

        @at-root #{&}__content {
          padding: 12px;

          .title {
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 12px;
          }

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
          }

          .explore {
            font-weight: bold;
            font-size: 10px;
          }
        }
      }

      .download:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); 
        
        .explore {
          text-decoration: underline;
          color: #1890ff;
        }
      }
    }
  }
}