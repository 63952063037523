.option-container {
  min-height: 65px;
  width: 200px;
  font-size: 14px;
  color: #939393;

  cursor: pointer;

  padding: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
  background: #EEEEEE;

  &:nth-child(odd) {
    margin-right: 27px;

    @media (max-width: 767px) {
      margin: 0;
      margin-bottom: 27px;
    }
  }

  &:hover:not(.active) {
    // border: 2px solid rgba(28, 119, 255, 0.05);
  }

  &.active {
    background: #F1F5F8
  }
}