.student-sidebar {
    background: #F7F7F8; //linear-gradient(174.35deg, #314FC8 0%, #536DD7 99.51%);
    padding: 20px 0 !important;
    height: 100vh;
    position: relative;
    border-right: 1px solid #ECECEF;
    ul.ant-menu{
        padding: 0 8px;
        font-family: Lato,sans-serif;
        letter-spacing: calc(.05rem - .05em);
        font-weight: 600;

        li.ant-menu-submenu,
        li.ant-menu-item {
            outline: none !important;
            margin: 2px 0 !important;
            font-size: 14px !important;
            border-radius: 5px !important;
           * {
            color: hsla(0,0%,13%,0.6) !important;
            font-weight: 600 !important;
           }

           .ant-menu-submenu-arrow::before {
                background: hsla(0,0%,13%,0.6) !important;
            }

            .ant-menu-submenu-arrow::after {
                background: hsla(0,0%,13%,0.6) !important;
            }

           &:not(.ant-menu-item-selected):not(.ant-menu-submenu):hover {
                background: #E4E4E8 !important;
                * {
                    color: hsla(0,0%,13%,1) !important;
                }
            }

            .ant-menu-submenu-title:hover{
                *{
                    color: hsla(0,0%,13%,1) !important;
                }

                .ant-menu-submenu-arrow::before {
                    background: hsla(0,0%,13%,1) !important;
                }

                .ant-menu-submenu-arrow::after {
                    background: hsla(0,0%,13%,1) !important;
                }
            }
        }
        li.ant-menu-item-selected{
            background: #E0E0F6 !important;
            border-radius: 5px !important;
            color: hsla(242,87.6%,62%,1) !important;
            * {
                color: hsla(242,87.6%,62%,1) !important;
            }
            &::after {
                position: absolute;
                top: 9px !important;
                left: 10px !important;
                right: auto !important;
                height: 55% !important;
                bottom: 0;
                border-right: 0;
            }
            &:focus {
                border: 2px solid hsla(242,87.6%,62%,1) !important;
              }
        }

        li {
            .ant-menu-item-group-title {
                color: hsla(0,0%,13%,1) !important;
                font-family: Lato,sans-serif;
                // letter-spacing: calc(.05rem - .05em);
                font-weight: 600;
            }
        }
    }
}
