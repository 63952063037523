$text-color-m: #9F9F9F !default;

.miscellaneous {
  ul {
    list-style: disc;
    padding: 0;
    list-style-position: inside;
    li {
        color: $text-color-m;
        span {
            font-weight: bold;
        }
    }
  }
}