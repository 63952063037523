$bg-color-i  : #BAEAFA !default;
$text-color: #6C9CAB;

.interest-container {
  label {
    background: $bg-color-i;
    color: $text-color;
    padding: 5px 10px;
    margin: 0 10px;
    min-width: 80px;
    font-size: 14px;
    text-align: center;
  }

  label:first-child {
    margin-left: 0;
  }
}