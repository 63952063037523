.form {
  .form-field {
    width: 100%;
    
    label {
      font-size: 12px;
      margin-bottom: 0.2rem;
    }

    .form-control {
      font-size: 16px;

      option {
        font-size: 12px;
      }
    }

    .form-control:disabled, .form-control[readonly] {
      opacity: 0.75 !important;
    }

    textarea {
      width: 100%;
      margin-top: 0.4rem;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    select:focus,
    textarea:focus,
    input:focus {
      font-size: 16px;
    }
  }
}
