$text-gray: hsla(0,0%,13%,0.6) !default;
$text-white: #FFFFFF;
$text-black: #000000;
$border-color: hsla(0,0%,13%,0.6);
$bg-color: #F7F7F8;

.page-sidebar {
  color: $text-gray;
  padding: 25px;
  min-width: 220px;
  position: relative;
  background: $bg-color;
  min-height: 100%;
  font-family: Lato,sans-serif;
  border-right: 1px solid #ECECEF;
  
  @media (max-width: 768px) {
    min-height: unset;
    padding-top: 25px;
  }

  @at-root #{&}__stepper {
    color: $text-gray;
    font-weight: 600;
    letter-spacing: calc(.05rem - .05em);
    @media (max-width: 768px) {
      display: none;
    }

    label {
      margin: 0;
      margin-left: 12px;
    }
  
    .bullet {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      font-size: 9px;
      border: 1.2px solid $border-color;
  
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .done {
      /*border-color: transparent;*/
      /*background: rgba(28, 119, 255, 0.25);*/
      // color: hsla(0,0%,13%,1);
      color: hsla(242,87.6%,62%,1);
      border-color: hsla(242,87.6%,62%,1);
    }

    .active {
      /*border-color: rgba(28, 119, 255, 0.3);*/
      color: hsla(0,0%,13%,1);
    }
  
    .line-connector {
      height: 0;
      width: 24px;
      border: 1px solid $border-color;
      transform: rotate(90deg); 
  
      margin: 6.5px 0;
      margin-left: -4.5px;

      &.active {
        border-color: hsla(242,87.6%,62%,1);
      }
    }
  }

  @at-root #{&}__stepper-mobile {
    display: none;

    .challenge {
      font-size: 50px;
      color: #212529;

      @media (max-width: 768px) {
        font-size: 25px;
      }

      @media (max-width: 325px) {
        font-size: 17px;
      }
    }

    .next-challenge {
      @media (max-width: 325px) {
        font-size: 12px;
      }
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }

  @at-root #{&}__warning {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .no-sidebar {
    padding: 0;
  }
}