$border-gray  : #E3E3E3 !default;

$small-screen : 767px;
.dialogContainer {
  @at-root #{&}__head {
    padding: 20px 30px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  @at-root #{&}__content {
    padding: 0px 30px;
    max-width: 500px;
    min-width: 400px;
    text-align: center;
    white-space: pre-wrap;
    font-size: 16px;
    margin-bottom: 10px;

    @media (max-width: $small-screen) {
      width: 100%;
      max-width: unset;
      min-width: unset;
    }
  }

  @at-root #{&}__foot {
    padding: 20px 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}