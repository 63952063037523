.home-view {
  width: 100%;
  height: 100%;
  padding: 25px;

  @media (max-width: 768px) {
    padding: 25px;
    width: 100%;
    height: calc(100vh - 48px);
  }

  .warning-notice {
    display: none;
    font-size: 10px;
    margin-top: 50px;
    padding: 20px 0;
    text-align: center;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .decoding-view {
    .code {
      width: calc(100% - 200px);
      margin-bottom: 20px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .scavenger-hunt-view {
    .hash {
      width: calc(100% - 200px);
      margin-bottom: 20px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .public-key-view {
    .ssh-key {
      width: calc(100% - 200px);

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .comprehension-view {
    @media (max-width: 768px) {
      .option-container {
        margin-right: 0;
      }
    }
  }

  .page-challenge-completed {
    .bullet {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      font-size: 25px;
      border: 1.2px solid #E0E0E0;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      &.done {
        border-color: transparent;
        background: rgba(28, 119, 255, 0.06);
        color: rgba(28, 119, 255, 0.63);
      }
    }
  
    .success, .completed {
      text-align: center;
      width: 100%;
    }

    .success {
      max-width: 495px;
    }

    .completed {
      max-width: 425px;
    }

    @media (max-width: 768px) {
      .font-25 {
        font-size: 20px;
      }

      .font-15 {
        font-size: 14px;
      }
    }
  }
}