.logout{
    background: #625DF5;//linear-gradient(174.35deg, #536DD7 99.51%, #314FC8 0%);
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    z-index: 1;
    letter-spacing: calc(.05rem - .05em);
    padding: 10px 16px 10px 10px;
    color: #FFF;
    font-weight: 500;

    .logout-icon{
        cursor: pointer;
    }

    .profile-icon {
        color: hsla(242,87.6%,62%,1);
        background: #ffffff;
        width: 28px;
        height: 28px;
        border-radius: 50%;
    }

    &:hover {
        background: #342DF2;
    }
  }