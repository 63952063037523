.admission-view {
  display: flex;
  flex-direction: row;

  @at-root #{&}__left {
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      flex: 100%;

      .font-30 {
        font-size: 20px;
      }

      .font-22 {
        font-size: 16px;
      }

      .font-18 {
        font-size: 14px;
      }
    }
  }

  @at-root #{&}__right {
    @media (max-width: 768px) {
      display: none;
    }
  }
}