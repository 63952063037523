$banner-bg   : #EBF1F6 !default;
$bg-gray     : #F8F8F9 !default;

// BORDER COLOR
$border-gray : #E4E4E4 !default;

// COLOR
$prime-text  : #4f49f3 !default;
$blue-text   : #1890ff !default;
$black-text  : #000000 !default;
$gray-text   : #AEAEAE !default;
$red-text    : #E62424 !default;
$green-text  : #00BF13 !default;
$white-text    : #fff !default;

.bg-gray {
  background-color: $bg-gray;
}

// DYNAMIC INFO MODAL 
.dynamic-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
      margin: 0;
      font-weight: 600;
  }
  p{
      text-align: center;
      margin: 20px 0;
  }
}

.bg-tower {
  background: url('../images/tower.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;
}

.bg-city {
  background: url('../images/city.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0;
}

.pos-relative {
  position: relative;
}

.width-max {
  width: 100%;
}

.top-index {
  z-index: 999999;
}

.comment-section {
  height: 500px;
  padding-top: 25px;
  position: relative;
  padding-bottom: 100px;
}

.banner-container {
  padding: 20px 0;
  background: $banner-bg;
  margin-bottom: 40px;

  .display-4 {
    font-weight: bold;
    font-size: 30px;
  }

  &.no-bg {
    background: none;
    border-bottom: 1px solid $border-gray;
  }
}

.content-limit {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.status-container {
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 8px;
  margin-left: 10px;
}

.hidden {
  display: none !important;
}


// COLORS
.color-link {
  color: $blue-text;
}

.color-prime {
  color: $prime-text;
}

.color-gray {
  color: $gray-text;
}

.color-black {
  color: $black-text;
}

.color-red {
  color: $red-text;
}

.color-green {
  color: $green-text;
}

.color-white {
  color: $white-text;
}

// WEIGHT
.font-bold {
  font-weight: bold;
}

.fw-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

// TEXT TRANSFORM
.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

// TEXT ALIGN 
.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}



// TEXTAREA
.resize-none {
  resize: none;
}


// FONT SIZE 
.font-9 {
  font-size: 9px !important;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-25 {
  font-size: 25px;
}

.font-30 {
  font-size: 30px;
}

.font-32 {
  font-size: 32px;
}

// CURSOR
.c-pointer {
  cursor: pointer;
}

.no-hover-style:hover {
  text-decoration: none;
}

.hover-style:hover {
  text-decoration: underline;
}

// PADDING
.main-padding {
  padding: 0 50px;
}

.pad-right-30 {
  padding-right: 30px;
}

.pad-right-20 {
  padding-right: 20px;
}

.pad-right-70 {
  padding-right: 70px;
}

.pad-right-80 {
  padding-right: 80px;
}

.no-padding {
  padding: 0 !important;
}


// WORD BREAK
.word-break {
  word-break: break-all;
}

// REQUIRED FIELD
.is-required::after {
  content:" *"; 
  color: red;
}

// ICON FOR REDIRECTION
.icon-redirect {
  color: #274AFF;

  svg {
    font-size: 20px;
  }
}