$bg-color: #2E2E2E !default;
$white: #ffffff !default;

.login-container{
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @at-root #{&}__header {
    background: $bg-color;
    color: $white;

    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 24px;

    h4 {
      margin: 0;
    }
  }

  @at-root #{&}__welcome {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  @at-root #{&}__form {
    width: 500px;
    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
    }

    .form-field {
      width: 100%;
    }

    .form-field + .form-field {
      margin-top: 10px;
    }

    div:last-child {
      margin-top: 30px;
    }
  }
}