$bg-white: #FFFFFF !default;
$text-gray  :  #888888;
$text-black :  #000000;

.comment-box {
  padding: 20px 30px;
  background: $bg-white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  
  .quote {
    font-family: Times;
    font-size: 30px;
    position: absolute;
    top: -10px;
    left: -15px;
    color: $text-black;
  }

  p {
    text-align: center;
    font-size: 16px;
    position: relative;
    max-width: 313px;
    color: $text-gray;
  }

  .userImg {
    width: 40px;
    height: 40px;
    background-color: $text-gray;
    border-radius: 50%;
  }
}