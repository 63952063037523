.student-profile {
  width: 100%;

  .student-profile__container {
    .profile-img {
      width: 180px;
      height: 180px;
      position: relative;
      cursor: pointer;
      margin-top: .5rem;
      margin-right: 30px;

      img {
        object-fit: cover;;
      }

      .profile-img__overlay {
        color: #9A9A9A;
        background: rgba(#e9ecef, 0.75);
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 0;
        transition: .3s ease;

        svg {
          width: 24px; 
          height: 18px; 
          margin-right: 10px;
        }
      }
    }

    .profile-img:hover .profile-img__overlay {
      bottom: 0;
      height: 100%;
    }
  }
}