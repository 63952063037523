.admission-sidebar {
	background: linear-gradient(174.35deg,#314fc8,#536dd7 99.51%);
    height: 100vh;
    position: relative;
    min-height: 100%;
    min-width: 220px;
    color: #888;
}

.admission-white-color {
	color: #fff;
}