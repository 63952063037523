$black: #2E2E2E !default;
$white: #ffffff !default;
$gray:  #9B9B9F !default;
$blue:  #1C77FF !default;

.header{
  background: $white;
  padding: 12px 25px;
  box-shadow: 0px 4px 4px rgba(167, 167, 167, 0.25);

  @at-root #{&}__container {
    .header-brand {
      h4 {
        margin: 0;
        font-size: 25px;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    a.active,
    .header-brand {
      color: $black;
      font-weight: bold;
      h4 {
          font-weight: bold;
      }
    }

    a {
      color: $gray;
    }
  }

  @at-root #{&}__search {
    width: 250px;
    margin: 0 40px;
    position: relative;

    display: flex;
    align-items: center;

    form {
      input {
        font-size: 14px;
        max-height: 28px;
        padding-left: 30px;
      }
    }

    .search-icon {
      position: absolute;
      top: 7px;
      left: 9px;
      font-size: 12px;
      color: #656565;
    }
  }

  @at-root #{&}__menu {
    a {
        padding: 0 16px;
        font-size: 15px;
    }
  }
}