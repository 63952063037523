.interview-prep {
  width: 100%;

  .interview-prep__all-prep {
    .preparations {
      margin: 10px 0;

      .preparation {
        height: min-content;
        border-radius: 8px;
        margin-right: 30px;
        margin-bottom: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: box-shadow .3s;

        @at-root #{&}__content {
          padding: 12px;

          .title {
            text-transform: capitalize;
            font-weight: 500;
            margin-bottom: 10px;
            font-size: 12px;
          }

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
          }

          .explore {
            font-weight: bold;
            font-size: 10px;
          }
        }
      }

      .preparation:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); 
        
        .explore {
          text-decoration: underline;
          color: #1890ff;
        }
      }
    }
  }
}

.interview-prep-dialog {
  width: 90vw;
  height: 100%;
  min-height: 100%;
}

.interview-prep-view {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  @at-root #{&}__right {
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      flex: 100%;

      .font-30 {
        font-size: 20px;
      }

      .font-20 {
        font-size: 16px;
      }

      .font-18 {
        font-size: 14px;
      }
    }
  }

  @at-root #{&}__left {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.interview-prep-option {
  cursor: pointer;
  min-height: 45px;
  width: 100%;
  color: #939393;
  white-space: pre-wrap;
  padding: 10px;
  margin-bottom: 21px;
  background: #F1F5F8;
  border: 1px solid #EEEEEE;
  border-radius: 5px;

  &.active, &:hover {
    background: rgba(28, 119, 255, 0.07);
  }
}