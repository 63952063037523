circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle-label {
  transform: translateY(0.5em);
}

.circle-progress {
    font-size: 1em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(-0.25em);
}