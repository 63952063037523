$bg-color-gray:  #F1F5F8 !default;
$white:          #FFFFFF !default;
$btn-background: rgba(28, 119, 255, 0.07);
$btn-color:      #1C77FF !default;

.jumbotron {
  background-color: $bg-color-gray;
}

.btn {
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}

.btn-get-started {
  font-size: 18px !important;
  padding: .525rem 1.65rem;

  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}


.btn-info {
  background: $btn-background;
  color: $btn-color;
  border: none;

  &:active,
  &:focus,
  &:hover {
    background: $btn-color;
    color: $white;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: $btn-color;
    border: none;
  }

    &:focus {
      border: none;
      box-shadow: none;
      background: $btn-background;
      color: $btn-color;
    }

    &.rounded-3 {
      border-radius: 3px;
    }
}

.btn-info.gray {
  color: #C4C4C4;
  background: #F9F9F9;

  &:active,
  &:focus,
  &:hover {
    background: $btn-color;
    color: $white;
  }
}

.btn-outline-info {
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }
}


.top-index-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.54);
  z-index: 999999;
}

// ANTD OVERRIDES
.ant-layout-header {
  background: linear-gradient(1deg,#314fc8,#536dd7 99.51%) !important;
  padding: 0 30px !important;
  height: 55px !important;
  z-index: 1;
  box-shadow: none;
}

.ant-form-item-label > label {
  font-size: 12px !important;
}

.ant-modal-title {
  font-weight: 600 !important;
}

.ant-menu-inline .ant-menu-item {
  margin: 0 !important;
}
.ant-breadcrumb a, .ant-breadcrumb > span:last-child {
  color: #AEAEAE !important;
}

.ant-breadcrumb a:hover {
  color: #40a9ff !important;
}

.ant-btn {
  border-radius: 5px !important;
  font-weight: 600 !important;
}

.ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card {
  height: 120px !important;
  width: 120px !important;
  margin: 0 !important;
}

.ant-table-thead > tr > th {
  background: transparent !important;
  font-size: 12px;
}

button.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}